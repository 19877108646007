import { render, staticRenderFns } from "./main.vue?vue&type=template&id=0ab35edc&scoped=true&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab35edc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Head: require('/home/php1/m-nuxt/components/Head.vue').default,Footer: require('/home/php1/m-nuxt/components/Footer.vue').default})
