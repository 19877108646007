

import Vue from 'vue';
import TripartiteChangeStorage from '/pages/tripartite/changeStorage/index'
import TripartitePopAd from '/pages/tripartite/popAd/index'
import TripartiteEchatCustomer from '/pages/tripartite/echat/index'
import TripartiteCommon from '/pages/tripartite/common/index'
import TripartiteBuried from '/pages/tripartite/buried/index'
import TripartiteSeoDefault from '/pages/tripartite/seo/index'
import TripartiteAside from '/pages/tripartite/aside/index'
import TripartiteRegisterSuccess from '/pages/tripartite/registerSuccess/index'
import TripartiteBusiness from '/pages/tripartite/business/index'

export default {
    name: 'layouts-default',
    middleware: 'stats',
    components: {
        TripartiteChangeStorage,
        TripartitePopAd,
        TripartiteEchatCustomer,
        TripartiteCommon,
        TripartiteBuried,
        TripartiteSeoDefault,
        TripartiteAside,
        TripartiteRegisterSuccess,
        TripartiteBusiness
    },
    data() {
        return {
            hasDelay: false
        }
    },
    async created() {
        if(process.client){ //客户端数据获取
            let country = this.$storage.get('country')
            let currency = this.$storage.get('currency')
            let language = this.$storage.get('language')
            if(!country?.id||!currency?.code||!language?.identify){
                let response = await this.$api.common.getCountryIp({
                    origin:location.origin,
                    getByIp:true
                })
                let res = response?.result
                if(res){
                    this.$storage.set('country',res,true)
                    const currency = {
                        code: res.currencyCode,
                        symbol: res.currencySymbol
                    };
                    const language = {
                        identify: res.language,
                        langCode: res.langCode,
                        language: res.languageName
                    };
                    this.$storage.set('currency',currency,true)
                    this.$storage.set('language',language,true)
                }
            }
        }
    },
    mounted() {
        this.initialSet()
        this.setProto()
    },
    methods: {
        // 购物车更新
        setProto() {
            const updateBagQuantity = () => {
                this.$updateBodyBagQuantity && this.$updateBodyBagQuantity()
                this.$updateHeadBagQuantity && this.$updateHeadBagQuantity()
                this.$updateAsideBagQuantity && this.$updateAsideBagQuantity()
            };
            Vue.prototype.$updateBagQuantity = updateBagQuantity
        },
        // 初始化
        initialSet() {
            const keyArr = ['PaySuccess', 'PaySuccess-Old'];
            const times = keyArr.includes(this.$route.name) ? 0 : 3000;
            let clear = setTimeout(() => {
                this.hasDelay = true
                clearTimeout(clear)
            }, times)
        }
    },
    computed: {},
    watch: {}
}
