
import Vue from 'vue';

export default {
    name: 'layouts-main',
    middleware: 'stats',
    components: {},
    data() {
        return {}
    },
    async created() {
        if(process.client){ //客户端数据获取
            let country = this.$storage.get('country')
            let currency = this.$storage.get('currency')
            let language = this.$storage.get('language')
            if(!country?.id||!currency?.code||!language?.identify){
                let response = await this.$api.common.getCountryIp({
                    origin:location.origin,
                    getByIp:true
                })
                let res = response.result
                if(res){
                    this.$storage.set('country',res,true)
                    const currency = {
                        code: res.currencyCode,
                        symbol: res.currencySymbol
                    };
                    const language = {
                        identify: res.language,
                        langCode: res.langCode,
                        language: res.languageName
                    };
                    this.$storage.set('currency',currency,true)
                    this.$storage.set('language',language,true)
                }
            }
        }
    },
    mounted() {
        this.setProto()
    },
    methods: {
        // 购物车更新
        setProto() {
            const updateBagQuantity = () => {
                this.$updateBodyBagQuantity && this.$updateBodyBagQuantity()
                this.$updateHeadBagQuantity && this.$updateHeadBagQuantity()
                this.$updateAsideBagQuantity && this.$updateAsideBagQuantity()
            };
            Vue.prototype.$updateBagQuantity = updateBagQuantity
        },
    }
}
